import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectId: 0,
    ohx_TokenFlag :false,
    selectedItem: null ,
    isLog:true,
    showMyPartInfo:false,
  },
  getters: {
    getohx_TokenFlag(state){
      return state.ohx_TokenFlag
    }
  },
  mutations: {
    refreshohx_TokenFlag(state,payload){
      state.ohx_TokenFlag = payload;
    },
    SET_SELECTED_ITEM(state, item) {
      state.selectedItem = item;
    },
    SET_LOGIN_STATE(state, value) {
      state.isLog = value;
      state.isLog = true;
    },
    SET_PART_INFO_STATE(state, value) {
      state.showMyPartInfo = value;
    }
  },
  actions: {
    setSelectedItem({ commit }, item) {
      commit('SET_SELECTED_ITEM', item);
    },

  },
  modules: {}
});
