import Vue from "vue";
import VueRouter from "vue-router";


// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/moreInfo",
    name: "moreInfo",
    component: () => import('@/views/moreinfo.vue'),
    meta: { keepAlive: true }
  },
  {
    path: "/test",
    name: "test",
    component: () => import('@/views/test.vue'),
    meta: { keepAlive: true }
  },
  {
    path: "/",
    name: "show2",
    component: () => import('@/views/show.vue'),
    meta: { keepAlive: true }
  },
  {
    path: "/show",
    name: "show",
    component: () => import('@/views/show.vue'),
    meta: { keepAlive: true }
  },
  {
    path: "/openlayer",
    name: "openlayer",
    component: () => import('@/views/show.vue'),
    meta: { keepAlive: true }
  },
  //登陆页面
  {
    path: "/Login",
    name: "Login",
    component: () => import('@/views/login/login.vue'),
    meta: { keepAlive: true }
  },

  // 注册页面
  {
    path: "/Register",
    name: "Register",
    component: () => import('@/views/login/register.vue'),
    meta: { keepAlive: true }
  },
  // 注册页面
  {
    path: "/changepassword",
    name: "changepassword",
    component: () => import('@/views/login/changepassword.vue'),
    meta: { keepAlive: true }
  },
  // 注册页面
  {
    path: "/myinfo",
    name: "myinfo",
    component: () => import('@/views/myInfo.vue'),
    meta: { keepAlive: true }
  },
  {
    path: "/testtt",
    name: "testtt",
    component: () => import('@/views/testtt.vue'),
    meta: { keepAlive: true }
  },
  {
    path: "/close",
    redirect: "/",
    meta: { keepAlive: true }
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes
});



export default router;
